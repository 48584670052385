@font-face {
    font-family: 'Roboto Regular';
    src: url('./assets/font/Roboto-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Light';
    src: url('./assets/font/Roboto-Light.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Bold';
    src: url('./assets/font/Roboto-Bold.ttf');
    font-weight: 500;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: 'Roboto Light', Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
}

#root {
    height: 100%;
}